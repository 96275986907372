const ValidationForms = (selectors: HTMLCollectionOf<HTMLFormElement>) => {
    window?.addEventListener('load', () => {
        Array.prototype.filter.call(selectors, (form) => {
            form?.addEventListener('submit', (event) => {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.classList.add('was-validated');
            }, false);
        });
    }, false);
};

export default ValidationForms;
