import { tns } from 'tiny-slider';
import Icons from 'components/ui/Icons';

const EnableCarouselSlider = (selectors) => {
    selectors.forEach((value) => {
        const defaults:ITinySliderOptions = {
            container: value,
            controlsText: [
                Icons.prev,
                Icons.next
            ],
            navPosition: 'bottom',
            mouseDrag: true,
            speed: 500,
            autoplayHoverPause: true,
            autoplayButtonOutput: false
        };

        let userOptions;
        if (value.dataset.carouselOptions !== undefined) {
            userOptions = JSON.parse(value.dataset.carouselOptions);
        }

        const options = {
            ...defaults,
            ...userOptions
        };

        return tns(options);
    });
};

export default EnableCarouselSlider;
