import Axios from 'axios';
import AxiosCallbackError from 'components/api/AxiosCallbackError';
import Icons from 'components/ui/Icons';
import Loaders from 'components/ui/Loaders';

const mail = document.querySelector('[data-newsletter-mail]') as HTMLInputElement;
const nodes = document.querySelector('.subscription-status') as HTMLElement;

const SubscribeToNewsletter = (selector) => {
    if (selector === null) {
        return;
    }

    window?.addEventListener('load', () => {
        Array.prototype.filter.call(selector, (form) => {
            form?.addEventListener('submit', async (event) => {
                const submit = event.submitter as HTMLElement;

                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    event.preventDefault();
                    submit.innerHTML = Loaders.circle;

                    try {
                        const { data } = await Axios.post<INewsletter>(
                            '/api/post/newsletter',
                            { mail: mail.value },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Accept: 'application/json'
                                },
                                params: { timestamp: new Date().getTime() }
                            }
                        );

                        while (nodes.firstChild) {
                            nodes.removeChild(nodes.lastChild as HTMLDivElement);
                        }
                        nodes.insertAdjacentHTML('beforeend', data as unknown as string);

                        setTimeout(() => {
                            submit.innerHTML = Icons.retry;
                        }, 1000);

                        submit.innerHTML = Icons.success;
                    } catch (error) {
                        AxiosCallbackError(error);
                    }
                }

                form.classList.add('was-validated');
            }, false);
        });
    }, false);
};

export default SubscribeToNewsletter;
